<!--支付模板-->
<template>
    <div class="PayTemplate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.finance.payTemplate.create')"
                >新建</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.finance.payTemplate.export')"
                >导出</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="630"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="名称" width="160" prop="name" v-if="showColumn('name')" />
                <el-table-column label="标志" width="120" prop="flag" v-if="showColumn('flag')" />
                <el-table-column
                    label="可支付(收银)"
                    width="110"
                    prop="canPay"
                    v-if="showColumn('canPay')"
                    key="canPay"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.canPay">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可退款(收银)"
                    width="110"
                    prop="canRefund"
                    v-if="showColumn('canRefund')"
                    key="canRefund"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.canRefund">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可抹零(收银)"
                    width="110"
                    prop="canFloor"
                    v-if="showColumn('canFloor')"
                    key="canFloor"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.canFloor">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可组合支付(收银)"
                    width="130"
                    prop="canCombinationPay"
                    v-if="showColumn('canCombinationPay')"
                    key="canCombinationPay"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.canCombinationPay">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可充值(会员)"
                    width="130"
                    prop="canRecharge"
                    v-if="showColumn('canRecharge')"
                    key="canRecharge"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.canRecharge">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="可退款(会员)"
                    width="110"
                    prop="canRechargeRefund"
                    v-if="showColumn('canRechargeRefund')"
                    key="canRechargeRefund"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.canRechargeRefund">
                            <i style="font-size: 24px; font-weight: bolder; color: #19c989">&#10004;</i>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建时间" width="160" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    width="145"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.finance.payTemplate.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="mini"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.finance.payTemplate.edit')"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
    </div>
</template>

<script>
import Util from '../../../js/Util';
import UrlUtils from '../../../js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';
export default {
    name: 'PayTemplate',
    components: { CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                search: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/finance/pay/template/page',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleCreate() {
            Util.nameJump(this, 'menu.finance.payTemplate.create', ['财务管理', '支付模板', '新建支付模板']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.finance.payTemplate.detail', ['财务管理', '支付模板', '支付模板详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.finance.payTemplate.edit', ['财务管理', '支付模板', '编辑支付模板'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '支付模板', '/finance/pay/template/export', this.form, codes);
        },
    },
};
</script>

<style scoped>
.PayTemplate .el-form-item {
    margin-bottom: 0;
}
</style>
